import { PoolEventResponse } from '@/utils/pool_types'
import { AnalyzedPoolEvent, analyzePoolEvent } from '@/utils/pools'
import { useEffect, useState } from 'react'

export const usePoolEventAnalysis: (
    poolEvent: Pick<PoolEventResponse, 'phases'>
) => AnalyzedPoolEvent = (poolEvent) => {
    const [analysis, setAnalysis] = useState(() => analyzePoolEvent(poolEvent))

    useEffect(() => {
        let interval: number

        // re-analyze at the next minute
        const timeout = setTimeout(() => {
            setAnalysis(analyzePoolEvent(poolEvent))
            // console.log('re-analyzed pool event at first minute')

            // setup interval to re-analysis every minute
            interval = setInterval(() => {
                setAnalysis(analyzePoolEvent(poolEvent))
                // console.log('re-analyzed pool event at minute')
            }, 60000)
        }, 60000 - new Date().getSeconds() * 1000)

        return () => {
            clearTimeout(timeout)
            clearInterval(interval)
        }
    }, [poolEvent])

    return analysis
}
