import { useState, useEffect, useCallback, useRef } from 'react'

const defaultOptions = {
    speed: 50,
    loop: false,
}

export const useTypewriter = (text: string[], options?: { speed?: number; loop?: boolean }) => {
    const [displayText, setDisplayText] = useState('')
    const opt = { ...defaultOptions, ...options }
    const timeout = useRef<number | undefined>()

    const updateWord = useCallback(
        (wordIndex: number, letterIndex: number): void => {
            if (wordIndex < text.length) {
                if (letterIndex < text[wordIndex].length) {
                    setDisplayText(
                        (prevText) => (letterIndex > 0 ? prevText : '') + text[wordIndex].charAt(letterIndex)
                    )
                    timeout.current = setTimeout(() => updateWord(wordIndex, ++letterIndex), opt.speed)
                } else {
                    timeout.current = setTimeout(() => updateWord(++wordIndex, 0), opt.speed * 50)
                }
            } else if (opt.loop) {
                timeout.current = setTimeout(() => updateWord(0, 0), opt.speed * 100)
            }
        },
        [opt.loop, opt.speed, text]
    )

    useEffect(() => {
        updateWord(0, 0)

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
        }
    }, [updateWord])

    return displayText
}
