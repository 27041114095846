import { PoolEventResponse } from '@/utils/pool_types'
import { Alert, Typography } from '@mui/material'
import { FC } from 'react'
import WarningIcon from '@mui/icons-material/WarningRounded'
import EventIcon from '@mui/icons-material/Event'
import { useTranslation } from 'next-i18next'
import { useDateFormatter } from '@/hooks/useDateFormatter'
import { getFormattedDateFormat } from '@/utils/time'
import { parseISO } from 'date-fns'
import InfoIcon from '@mui/icons-material/Info'
import { usePoolEventAnalysis } from '@/hooks/usePoolEventAnalysis'

export const PoolEventState: FC<{
    poolEvent: PoolEventResponse
    options?: {
        showOnePredictionPhaseComplete?: boolean
        hidePicksNotAvailableWarning?: boolean
    }
}> = ({ poolEvent, options }) => {
    const dateFormatter = useDateFormatter()
    const { t } = useTranslation('pools')
    const analysis = usePoolEventAnalysis(poolEvent)

    const {
        picksLeft,
        partial,
        nextPickPhase: nextPicksPhase,
        currentPickPhase: currentPicksPhase,
    } = analysis
    const hasPhases = !!poolEvent.phases.length

    const onePredictionPhaseComplete =
        options?.showOnePredictionPhaseComplete && hasPhases && partial && picksLeft

    const allPredictionPhasesComplete = hasPhases && !picksLeft
    const comingSoon = !hasPhases
    const nextPredictionPhaseStarts = !currentPicksPhase && nextPicksPhase && partial
    const firstPredictionPhaseStarts = !currentPicksPhase && nextPicksPhase && !partial
    const picksNotCurrentlyAvailable =
        !options?.hidePicksNotAvailableWarning && currentPicksPhase && !currentPicksPhase.data

    return (
        <>
            {(nextPredictionPhaseStarts || firstPredictionPhaseStarts || currentPicksPhase) && (
                <Typography variant="body2" color="secondary" marginBottom="10px">
                    <i>{t('labelAllTimesInLocalTime')}</i>
                </Typography>
            )}
            {onePredictionPhaseComplete && (
                <Typography variant="body2" color="warning.main">
                    <WarningIcon
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />
                    &nbsp;{t('labelOnePredictionPhaseComplete')}
                </Typography>
            )}
            {allPredictionPhasesComplete && (
                <Typography variant="body2" color="info.main">
                    <InfoIcon
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />
                    &nbsp;{t('labelAllPredictionPhaseComplete')}
                </Typography>
            )}
            {comingSoon && (
                <Typography variant="body2" color="warning.main">
                    <WarningIcon
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />
                    &nbsp;{t('labelComingSoon')}
                </Typography>
            )}
            {currentPicksPhase && (
                <Typography variant="body2">
                    <EventIcon
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />
                    &nbsp;
                    {t('labelCurrentPredictionPhaseEnds', {
                        date: dateFormatter(
                            parseISO(currentPicksPhase.entryEndAt),
                            getFormattedDateFormat(parseISO(currentPicksPhase.entryEndAt))
                        ),
                    })}
                </Typography>
            )}
            {nextPredictionPhaseStarts && (
                <Typography variant="body2">
                    <EventIcon
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />
                    &nbsp;
                    {t('labelNextPredictionPhaseStarts', {
                        date: dateFormatter(
                            parseISO(nextPicksPhase.entryStartAt),
                            getFormattedDateFormat(parseISO(nextPicksPhase.entryStartAt))
                        ),
                    })}
                </Typography>
            )}
            {firstPredictionPhaseStarts && (
                <Typography variant="body2">
                    <EventIcon
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />
                    &nbsp;
                    {t('labelFirstPredictionPhaseStarts', {
                        date: dateFormatter(
                            parseISO(nextPicksPhase.entryStartAt),
                            getFormattedDateFormat(parseISO(nextPicksPhase.entryStartAt))
                        ),
                    })}
                </Typography>
            )}
            {picksNotCurrentlyAvailable && (
                <Alert severity="warning" sx={{ marginTop: '10px' }}>
                    {t('labelPicksCurrentlyNotAvailable')}
                </Alert>
            )}
        </>
    )
}
