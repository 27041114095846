import {
    Box,
    Button,
    Container,
    IconButton,
    Link,
    Rating,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
    useMediaQuery,
} from '@mui/material'
import { apiHelper } from '../../apiHelper'
import { withServerSideProps } from '../../utils/withServerSideProps'
import { NextPageWithLayout } from '../_app'
import { Page, PoolEventResponse, PoolReviewResponse } from '../../utils/pool_types'
import { PoolLayout, getPoolPageLayout } from '../../components/pools/PoolLayout'
import { useTranslation } from 'next-i18next'
import { PoolEventCarouselItem } from '@/components/pools/PoolEventCarouselItem'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { useMemo } from 'react'
import _ from 'lodash'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { PageHead } from '@/components/PageHead'
import { analyzePoolEvent } from '@/utils/pools'
import NextLink from 'next/link'
import VerifiedIcon from '@mui/icons-material/Verified'
import CloseIcon from '@mui/icons-material/Close'
import { format, parseISO } from 'date-fns'
import { getDateFnsLocaleFromNextjsLocale } from '@/utils/time'
import { Logo } from '@/components/pools/Logo'
import { PoolLeague } from '@/utils/pool_leagues_types'
import { getLocalizedValue } from '@/utils/i18n_client'
import Typewriter from '@/components/Typewriter'

export const getServerSideProps = withServerSideProps({
    translations: ['pools'],
    session: true,
    onlyRunIfSession: false,
    pageServerSideProps: async ({ req, query }, { session }) => {
        const poolEvents: PoolEventResponse[] = await apiHelper.get(`${process.env.API_URL}/api/pools/events`)

        const recentReviews: Page<PoolReviewResponse> = await apiHelper.get(
            `${process.env.API_URL}/api/pools/reviews/recent`
        )

        const leagues: PoolLeague[] = await apiHelper.get(`${process.env.API_URL}/api/pools/leagues`)

        return {
            props: {
                poolEvents,
                recentReviews,
                leagues,
            },
        }
    },
})

export const SecondaryBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#eee',
    paddingTop: '25px',
    paddingBottom: '25px',
    width: '100%',
    marginTop: '15px',
}))

export const GradientTypography = styled(Typography)(({ theme }) => ({
    // https://cssgradient.io/
    display: 'inline-block',
    backgroundImage: theme.palette.primaryGradient,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    WebkitTextFillColor: 'transparent',
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
}))

export const StrokedTypography = styled(Typography)(({ theme }) => ({
    // https://cssgradient.io/
    color: '#000000',
    display: 'inline',
}))

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#f5f5f9',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 600,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'normal',
        border: '1px solid #dadde9',
        borderRadius: '10px',
    },
}))

const DynamicPool: NextPageWithLayout<{
    poolEvents: PoolEventResponse[]
    recentReviews: Page<PoolReviewResponse>
    leagues: PoolLeague[]
}> = ({ poolEvents, recentReviews, leagues }) => {
    const { t, i18n } = useTranslation('pools')
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const poolEventsChunks = useMemo(
        () =>
            _.chunk(
                poolEvents
                    .map((poolEvent) => ({
                        poolEvent,
                        analysis: analyzePoolEvent(poolEvent),
                    }))
                    .filter((item) => item.poolEvent.phases.length && !item.analysis.partial),
                mobile ? 1 : 3
            ),
        [mobile, poolEvents]
    )

    const leaguesChunks = useMemo(
        () =>
            _.chunk(
                leagues,

                mobile ? 1 : 3
            ),
        [mobile, leagues]
    )

    return (
        <>
            <PageHead title={t('indexTitle')} description={t('indexDescription')} />
            <PoolLayout showLogo={false}>
                <>
                    <Box
                        paddingY="75px"
                        sx={{
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderBottomWidth: '7px',
                            borderBottomColor: '#0B00B4',
                            borderBottomStyle: 'solid',
                            marginBottom: '-20px',
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="row"
                            gap="25px"
                        >
                            <Box
                                flex={2}
                                justifyContent="flex-end"
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                }}
                            >
                                <Box width={256} height={256}>
                                    <Logo
                                        style={{
                                            zIndex: 100,
                                            position: 'relative',
                                        }}
                                        logoColor="#000"
                                    />
                                </Box>
                            </Box>
                            <Box flex={3}>
                                <GradientTypography
                                    fontWeight={800}
                                    variant="h1"
                                    textTransform="lowercase"
                                    textAlign="left"
                                >
                                    {t('labelPools')}
                                </GradientTypography>
                                <Typography variant="h2" fontWeight={700}>
                                    <Typewriter
                                        text={[
                                            'pour votre levée de fonds.',
                                            'entre amis.',
                                            'de bureau.',
                                            'pour toutes les occasions.',
                                        ]}
                                        speed={40}
                                        loop
                                    />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <SecondaryBox>
                        <Container>
                            <Box textAlign="center">
                                <Box marginY="35px">
                                    <Typography variant="h2" fontWeight={700}>
                                        {t('labelHowDoesItWork')}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        fontWeight={600}
                                        color="#000"
                                        marginTop="25px"
                                        style={{
                                            zIndex: 100,
                                            position: 'relative',
                                        }}
                                    >
                                        {t('landingCallout1Text')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        flexDirection={{
                                            xs: 'column',
                                            md: 'row',
                                        }}
                                        marginTop="25px"
                                        gap="15px"
                                        justifyContent="center"
                                    >
                                        <Box flex={0.33} paddingX="30px" paddingY="10px">
                                            <Typography variant="h4" sx={{ fontWeight: '700' }}>
                                                1. {t('labelStep1Title')}
                                            </Typography>
                                            <p>{t('labelStep1Text')}</p>
                                        </Box>
                                        <Box flex={0.33} paddingX="30px" paddingY="10px">
                                            <Typography variant="h4" sx={{ fontWeight: '700' }}>
                                                2. {t('labelStep2Title')}
                                            </Typography>
                                            <p>{t('labelStep2Text')}</p>
                                        </Box>
                                        <Box flex={0.33} paddingX="30px" paddingY="10px">
                                            <GradientTypography variant="h4" sx={{ fontWeight: '700' }}>
                                                3. {t('labelStep3Title')}
                                            </GradientTypography>
                                            <p>
                                                {t('labelStep3Text')}
                                                <br />
                                                <NextLink href="/pools/payment" passHref>
                                                    <Link fontWeight="bold">{t('learnMorePayment')}</Link>
                                                </NextLink>
                                            </p>
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" alignItems="center" gap="15px">
                                        <NextLink href="pools/new" passHref>
                                            <Button size="large" variant="contained">
                                                {t('buttonCreatePool')}
                                            </Button>
                                        </NextLink>
                                        <NextLink href="pools/learn-more" passHref>
                                            <Button>{t('learnMore')}</Button>
                                        </NextLink>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </SecondaryBox>

                    <Container>
                        {poolEventsChunks.length > 0 && (
                            <Box textAlign="center" marginTop="25px">
                                <StrokedTypography variant="h3" fontWeight={600}>
                                    {t('labelUpcomingEvents')}
                                </StrokedTypography>
                                <Box marginTop="25px">
                                    {poolEventsChunks.length > 0 ? (
                                        <Carousel
                                            showArrows={true}
                                            showThumbs={false}
                                            infiniteLoop={true}
                                            showIndicators={false}
                                            showStatus={false}
                                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                                hasPrev && (
                                                    <IconButton
                                                        type="button"
                                                        onClick={onClickHandler}
                                                        title={label}
                                                        style={{
                                                            position: 'absolute',
                                                            zIndex: 2,
                                                            top: 'calc(50% - 15px)',
                                                            width: 30,
                                                            height: 30,
                                                            cursor: 'pointer',
                                                            left: 15,
                                                        }}
                                                    >
                                                        <KeyboardArrowLeftIcon fontSize="large" />
                                                    </IconButton>
                                                )
                                            }
                                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                                hasNext && (
                                                    <IconButton
                                                        type="button"
                                                        onClick={onClickHandler}
                                                        title={label}
                                                        style={{
                                                            position: 'absolute',
                                                            zIndex: 2,
                                                            top: 'calc(50% - 15px)',
                                                            width: 30,
                                                            height: 30,
                                                            cursor: 'pointer',
                                                            right: 15,
                                                        }}
                                                    >
                                                        <KeyboardArrowRightIcon fontSize="large" />
                                                    </IconButton>
                                                )
                                            }
                                        >
                                            {poolEventsChunks.map((chunk, index) => (
                                                <Box
                                                    key={index}
                                                    width="100%"
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection={{
                                                        xs: 'column',
                                                        md: 'row',
                                                    }}
                                                    justifyContent="center"
                                                    alignItems="baseline"
                                                    padding="25px"
                                                >
                                                    {chunk.map((item) => (
                                                        <PoolEventCarouselItem
                                                            key={item.poolEvent.id}
                                                            poolEvent={item.poolEvent}
                                                            analysis={item.analysis}
                                                        />
                                                    ))}
                                                </Box>
                                            ))}
                                        </Carousel>
                                    ) : (
                                        <Typography>{t('noUpcomingEvents')}</Typography>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Container>
                    <Container>
                        <Box textAlign="center" marginTop="25px">
                            <StrokedTypography variant="h3" fontWeight={600}>
                                {t('leaguesLeagues')}
                            </StrokedTypography>
                            <Box marginTop="25px">
                                {leagues.length > 0 ? (
                                    <Carousel
                                        showArrows={true}
                                        showThumbs={false}
                                        infiniteLoop={true}
                                        showIndicators={false}
                                        showStatus={false}
                                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                            hasPrev && (
                                                <IconButton
                                                    type="button"
                                                    onClick={onClickHandler}
                                                    title={label}
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 2,
                                                        top: 'calc(50% - 15px)',
                                                        width: 30,
                                                        height: 30,
                                                        cursor: 'pointer',
                                                        left: 15,
                                                    }}
                                                >
                                                    <KeyboardArrowLeftIcon fontSize="large" />
                                                </IconButton>
                                            )
                                        }
                                        renderArrowNext={(onClickHandler, hasNext, label) =>
                                            hasNext && (
                                                <IconButton
                                                    type="button"
                                                    onClick={onClickHandler}
                                                    title={label}
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 2,
                                                        top: 'calc(50% - 15px)',
                                                        width: 30,
                                                        height: 30,
                                                        cursor: 'pointer',
                                                        right: 15,
                                                    }}
                                                >
                                                    <KeyboardArrowRightIcon fontSize="large" />
                                                </IconButton>
                                            )
                                        }
                                    >
                                        {leaguesChunks.map((chunk, index) => (
                                            <Box
                                                key={index}
                                                width="100%"
                                                display="flex"
                                                gap="20px"
                                                flexDirection={{
                                                    xs: 'column',
                                                    md: 'row',
                                                }}
                                                justifyContent="center"
                                                alignItems="baseline"
                                                padding="25px"
                                            >
                                                {chunk.map((item) => (
                                                    <Box>
                                                        <img
                                                            height="64"
                                                            src={`${process.env.NEXT_PUBLIC_IMAGES}/${item.badgeUrl}`}
                                                            alt={getLocalizedValue(item.name, i18n.language)}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Carousel>
                                ) : (
                                    <Typography>{t('leaguesNoLeagues')}</Typography>
                                )}
                            </Box>
                        </Box>
                    </Container>
                    {recentReviews.data.length > 0 && (
                        <SecondaryBox>
                            <Container>
                                <Typography textAlign="center" variant="h4">
                                    {t('recentReviews')}
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems={{
                                        xs: 'center',
                                        md: 'flex-start',
                                    }}
                                    overflow="auto"
                                    gap="25px"
                                    marginTop="25px"
                                    flexDirection={{ xs: 'column', md: 'row' }}
                                >
                                    {recentReviews.data.slice(0, 5).map((r) => (
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            maxWidth="200px"
                                        >
                                            <Rating readOnly value={r.rating} />

                                            {r.comment && (
                                                <>
                                                    {r.comment.length > 50 ? (
                                                        <NoMaxWidthTooltip arrow title={r.comment}>
                                                            <Typography
                                                                variant="body2"
                                                                textAlign="center"
                                                                align="center"
                                                            >
                                                                {r.comment.substring(0, 50)}
                                                                &nbsp;...
                                                            </Typography>
                                                        </NoMaxWidthTooltip>
                                                    ) : (
                                                        <Typography variant="body2" align="center">
                                                            {r.comment}
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                            <Typography variant="subtitle2">
                                                {r.type === 'owner'
                                                    ? t('reviewTypeOwner')
                                                    : t('reviewTypeUser')}
                                            </Typography>
                                            <Typography variant="caption">
                                                {format(parseISO(r.updatedAt), 'PP', {
                                                    locale: getDateFnsLocaleFromNextjsLocale(i18n.language),
                                                })}
                                            </Typography>
                                            <Typography variant="caption">
                                                {r.user ? r.user.name : t('anonymous')}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Container>
                        </SecondaryBox>
                    )}
                    <Container sx={{ marginTop: '25px' }}>
                        <Box textAlign="center">
                            <Typography variant="h3">{t('labelPricing')}</Typography>
                            <Box
                                marginTop={5}
                                overflow="auto"
                                width="100%"
                                sx={{ display: 'table', tableLayout: 'fixed' }}
                            >
                                <TableContainer component={Box}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('labelFeaturePlan')}</TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="h4">{t('labelFreePlan')}</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="h4">{t('labelProPlan')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <b>{t('labelFeatureCost')}</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureCostFree')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureCostPro')}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <b>{t('labelFeatureFees')}</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureFeesFree')} <sup>1</sup>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureFeesPro')} <sup>1</sup>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <b>{t('labelFeatureEmailReminders')}</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureEmailRemindersFree')}{' '}
                                                    <CloseIcon
                                                        color="error"
                                                        sx={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureEmailRemindersPro')}{' '}
                                                    <VerifiedIcon
                                                        color="success"
                                                        sx={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <b>{t('labelFeatureStandingUpdates')}</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureStandingUpdatesFree')}{' '}
                                                    <CloseIcon
                                                        color="error"
                                                        sx={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureStandingUpdatesPro')}{' '}
                                                    <VerifiedIcon
                                                        color="success"
                                                        sx={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <b>{t('labelFeatureAdsFree')}</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureAdsFreeFree')}{' '}
                                                    <CloseIcon
                                                        color="error"
                                                        sx={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('labelFeatureAdsFreePro')}{' '}
                                                    <VerifiedIcon
                                                        color="success"
                                                        sx={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box marginTop="25px">
                                    <sup>1</sup>{' '}
                                    <Typography variant="caption">{t('labelFeatureSub1')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </>
            </PoolLayout>
        </>
    )
}

DynamicPool.getPageLayout = getPoolPageLayout

export default DynamicPool
