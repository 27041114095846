import {
    getDateFnsLocaleFromNextjsLocale,
    getIntlLocalesArgumentNextjsLocale,
} from '@/utils/time'
import { format } from 'date-fns'
import { useRouter } from 'next/router'

export const useDateFormatter = () => {
    const router = useRouter()

    return (date: Date, formatPattern: string) =>
        format(
            date,
            formatPattern.replace(
                'toLocaleDateStringDayMonth',
                date.toLocaleDateString(
                    getIntlLocalesArgumentNextjsLocale(router.locale),
                    { month: 'short', day: 'numeric' }
                )
            ),
            {
                locale: getDateFnsLocaleFromNextjsLocale(router.locale),
            }
        )
}
