import Head from 'next/head'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { buildUrl } from '../utils/url'

export type PageHeadProps = {
    title: string
    description: string
    children?: any
    url?: string
    image?: string
}

export const PageHead: FunctionComponent<PageHeadProps> = ({
    title,
    description,
    children,
    url,
    image,
}) => {
    const { asPath, basePath, locale, locales } = useRouter()

    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL as string

    const finalUrl = url || buildUrl(baseUrl, basePath, asPath, locale)

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={finalUrl} />
            {image && <meta property="og:image" content={image} />}
            {/* <meta property="og:locale" content="fr_CA" /> */}
            <link
                rel="alternate"
                hrefLang="x-default"
                href={buildUrl(baseUrl, basePath, asPath)}
            />
            {locales?.map((currentLocale) => (
                <link
                    key={currentLocale}
                    rel="alternate"
                    hrefLang={currentLocale}
                    href={buildUrl(baseUrl, basePath, asPath, currentLocale)}
                />
            ))}
            {children}
        </Head>
    )
}
