import { LocalizedString, PoolResponse } from './pool_types'

export function getLocalizedValue(
    values: LocalizedString,
    language: string
): string {
    return values[language as keyof LocalizedString]
}

export function getPoolTitle(pool: PoolResponse, language: string): string {
    return pool.name
}

export function getPoolDescription(
    pool: PoolResponse,
    language: string
): string {
    return pool.name
}
