import { isAfter, isWithinInterval, parseISO } from 'date-fns'
import {
    DetailedPoolEntryResponse,
    PoolEventPhase,
    PoolEventResponse,
    PoolResponse,
    PoolStanding,
} from './pool_types'
import { PaymentAccount } from '@/types'

export enum EntryStates {
    MakeChoices,
    EditChoices,
    Disabled,
}

export function getConnectedAccountCurrency(
    connectedAccount: PaymentAccount
): string {
    return connectedAccount.defaultCurrency?.toUpperCase() ?? 'CAD'
}

export function getEntryState(
    entry: DetailedPoolEntryResponse,
    analysis: AnalyzedPoolEvent
): EntryStates {
    const activePhase = analysis.currentPickPhase

    if (!activePhase) {
        return EntryStates.Disabled
    }

    if (entry.picks && entry.picks.some((p) => p.phaseId === activePhase.id)) {
        return EntryStates.EditChoices
    }

    return EntryStates.MakeChoices
}

export type StandingRowData = {
    position: number
    points: number
}

export function getStandingRows(poolStanding?: PoolStanding): {
    entryXRefId: string
    current: StandingRowData
}[] {
    const rows: {
        entryXRefId: string
        current: StandingRowData
    }[] = []

    if (poolStanding?.data) {
        let position = 1
        let equalEntriesCount = 0
        poolStanding?.data
            .filter((x) => x.points >= 0)
            .forEach((row, index: number) => {
                if (
                    index > 0 &&
                    poolStanding?.data[index - 1].points !== row.points
                ) {
                    position += 1 + equalEntriesCount
                    equalEntriesCount = 0
                } else if (index > 0) {
                    equalEntriesCount++
                }

                rows.push({
                    entryXRefId: row.entryXRefId,
                    current: {
                        points: row.points,
                        position,
                    },
                })
            })
    }

    return rows
}

export type AnalyzedPoolEvent = {
    // if a picks phase has already been completed, this pool shows up as partial
    partial: boolean
    // if no picks are left, we can't create pool for this event
    picksLeft: boolean
    nextPickPhase: PoolEventPhase | null
    // phase for which entry start and end date contain the current date
    currentPickPhase: PoolEventPhase | null
    previousPickPhases: PoolEventPhase[]
    eventStartDate: string
    eventEndDate: string
}

export function analyzePoolEvent(
    poolEvent: Pick<PoolEventResponse, 'phases'>
): AnalyzedPoolEvent {
    let currentPickPhase: PoolEventPhase | null = null,
        nextPickPhase: PoolEventPhase | null = null,
        partial = false

    const previousPickPhases: PoolEventPhase[] = []

    let eventStartDate = ''
    let eventEndDate = ''

    poolEvent.phases
        .sort(
            (phase1, phase2) =>
                parseISO(phase1.entryStartAt).getTime() -
                parseISO(phase2.entryStartAt).getTime()
        )
        .forEach((phase, index) => {
            const entryStart = parseISO(phase.entryStartAt)
            const entryEnd = parseISO(phase.entryEndAt)

            if (index === 0) {
                eventStartDate = phase.entryEndAt
            }

            if (index === poolEvent.phases.length - 1) {
                eventEndDate = phase.endAt
            }

            // current pick phase is the one that wraps now inclusively
            if (
                isWithinInterval(Date.now(), {
                    start: entryStart,
                    end: entryEnd,
                })
            ) {
                currentPickPhase = phase
            }

            // previous pick phases are phases that ended before now
            if (isAfter(Date.now(), entryEnd)) {
                previousPickPhases.push(phase)
                partial = true
            }

            // next pick phase is the first one that starts after now
            if (!nextPickPhase && isAfter(entryStart, Date.now())) {
                nextPickPhase = phase
            }
        })

    return {
        partial,
        picksLeft: !!(nextPickPhase || currentPickPhase),
        nextPickPhase,
        currentPickPhase,
        previousPickPhases,
        eventStartDate,
        eventEndDate,
    }
}
