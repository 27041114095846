import { useTypewriter } from '@/hooks/useTypewriter'
import { FC } from 'react'

type TypewriterProps = {
    text: string[]
    speed?: number
    loop?: boolean
}

const Typewriter: FC<TypewriterProps> = ({ text, speed = 50, loop = false }) => {
    const displayText = useTypewriter(text, { speed, loop })

    return <>{displayText}</>
}

export default Typewriter
